import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorMarcel as author } from 'data/authors';
import img from 'img/blog/user-journey/cover.png';
import img1 from 'img/blog/user-journey/img1.png';
import img2 from 'img/blog/user-journey/img2.png';

const Content = () => {
  return (
    <Post>
      <p>We’ve just launched a User Journey preview in LiveSession!</p>
      <p>
        This feature allows you to discover the most important actions made during the user’s
        session.
      </p>
      <p>
        To open it, hover on <i>“pages”</i> link and click <i>“See whole journey”</i> :
      </p>
      <img src={img1} alt="User journey - preview" title="User journey in action" />
      <p>
        Next, you will see the timeline of the user’s page visit and events like Clicks, Errors,
        Rage Clicks, Form actions, and{' '}
        <a href="/help/how-do-the-custom-events-work/">Custom Events</a>.
      </p>
      <img src={img2} alt="User journey - modal" title="User journey with Timeline" />
      <p>
        If you have any other ideas for improvement, we’re happy to hear from you! Feel free to get
        in touch via live chat or send us an email to{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'User journey',
  url: '/blog/user-journey/',
  description: `Introducing User journey! This feature allows you to discover the most important actions made during the user’s session.`,
  author,
  img,
  imgSocial: img,
  date: '2020-12-16',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
